import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@objects/container'
import BreadCrumbs from '@objects/breadcrumb'
import Headline from '@objects/headline'
import { FormattedMessage, useIntl } from 'react-intl'
import Icon from '@objects/icon'
import Button from '@objects/button'
import FontSize from '@config/theme/definitions/fontSize'

const useStyles = makeStyles((theme) => ({
  contentRoot: {
    textAlign: 'center',
    paddingTop: theme.spacing(9),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(20),
    },
  },
  headerIcon: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginBottom: theme.spacing(8),
    '& svg': {
      height: '84px',
      width: '84px',
    },
  },
  underlinedBtn: {
    borderRadius: 0,
    borderBottom: `2px solid ${theme.palette.primary.main} !important`,
    padding: theme.spacing(0, 0, 1),
    fontSize: FontSize['base'],
    '& svg': {
      height: FontSize['3lg'],
      width: 'auto',
    },
  },
}))

export default function RootIndex(): ReactElement {
  const classes = useStyles()
  const intl = useIntl()

  return (
    <section>
      <Container background={'light'} type="nomargin">
        <BreadCrumbs
          crumbList={[
            {
              label: intl.formatMessage({
                id: 'navigation.breadcrumb.barriers.label',
              }),
              link: intl.formatMessage({
                id: 'navigation.breadcrumb.barriers.url',
              }),
            },
          ]}
        />
      </Container>

      <Container width={'sm'} className={classes.contentRoot}>
        <div className={classes.headerIcon}>
          <Icon name={'CheckCircle'} viewBox={'0 0 94 91'} />
        </div>
        <Headline level={1}>
          <FormattedMessage
            id={'reportBarrier.thanks.headline'}
            values={{ break: <br /> }}
          />
        </Headline>
        <Button className={classes.underlinedBtn} type={'underlined'} to={'/'}>
          <FormattedMessage id={'basket.checkout.thanks.home'} />
        </Button>
      </Container>
    </section>
  )
}
